import React from "react";
import { Box } from "@mui/material";
import Player from "../../components/Player/Player.tsx";
import AppCircularProgress from "../../components/UI/CircularProgress/AppCircularProgress.tsx";
import AppHeading from "../../components/UI/AppHeading/AppHeading.tsx";
import { useGetFirebaseStore } from "../../service/hooks/useGetFirebaseStore.ts";
import { useLocation } from "react-router-dom";
import theme from "../../theme/theme.ts";

import { isMobile } from "react-device-detect";
type Props = {};

const Music = (props: Props) => {
  const { data, loading } = useGetFirebaseStore("songs");
  // get first 6 songs
  const firstSixSongs = data.slice(0, 5);
  const location = useLocation();
  const { pathname } = location;
  return (
    <Box
      sx={{
        width: "100%",
        zIndex: 2,
        py: isMobile ? 4 : "3vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: pathname === "/music" && theme.palette.grey.black,
        overflowX: "hidden",
      }}
    >
      {pathname === "/music" && (
        <AppHeading title={"music"} link={"/music"} size={50} />
      )}
      <>{loading && <AppCircularProgress />}</>
      <Box
        sx={{
          width: isMobile ? "100%" : "85%",
          borderRadius: "10px",
          opacity: 0.6,
          paddingTop:
            isMobile && pathname === "/music"
              ? 3
              : pathname === "/music"
              ? 8
              : pathname === "/"
              ? 4
              : 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {pathname === "/" &&
          firstSixSongs.map((song) => {
            return (
              <>
                <Player
                  audio={song?.url}
                  name={song?.title}
                  producer={song?.producer}
                />
              </>
            );
          })}
        {pathname === "/music" &&
          data.map((song, index) => {
            return (
              <Player
                key={index}
                audio={song?.url}
                name={song?.title}
                producer={song?.producer}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default Music;
