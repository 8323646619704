import React, { useRef } from "react";
import { Box, Button } from "@mui/material";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
import theme from "../theme/theme.ts";
import AppHeading from "../components/UI/AppHeading/AppHeading.tsx";
import AppSwiper from "../components/AppSwiper/AppSwiper.tsx";
import Biography from "../pages/Biography/Biography.tsx";
import Music from "../pages/Music/Music.tsx";
import NewsContainer from "../components/NewsContainer/NewsContainer.tsx";
import LandingInfo from "../components/LandingInfo/LandingInfo.tsx";
import WorksSection from "../pages/Works/WorksSection.tsx";
import { useGetFirebaseStore } from "../service/hooks/useGetFirebaseStore.ts";
import CreditsContainer from "../components/Credit/CreditsContainer.tsx";
import { isMobile } from "react-device-detect";

const DefaultLayout = ({ imagesData }) => {
  const alignCenter = { display: "flex", alignItems: "start" };
  const { data: worksData } = useGetFirebaseStore("works");
  const { data: newsData } = useGetFirebaseStore("news");
  const { data: creditsData } = useGetFirebaseStore("credits");
  const parallax = useRef<IParallax>(null);

  const scroll = (to: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }
  };
  return (
    <Box>
      <Parallax
        ref={parallax}
        style={{
          background: "linear-gradient(#191919 , #080808 )",
          position: "absolute",
          width: " 100vw",
          top: 0,
          left: 0,
          height: "100vh",
        }}
        pages={isMobile ? 6.5 : 5.8}
      >
        {/* AREA FOR CAROUSEL */}
        <ParallaxLayer offset={0}>
          <Box
            style={{
              ...alignCenter,
              backgroundSize: "cover",
              width: "100vw",
              height: "100vh",
              opacity: 0.4,
            }}
          >
            <AppSwiper data={imagesData} />
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={0}
          style={{
            // zIndex: 1,
            width: "100%",
          }}
        >
          <Box
            style={{
              ...alignCenter,
              justifyContent: "end",
              alignItems: "center",
              flexDirection: "column",
              width: "100vw",
              height: "100%",
              color: theme.palette.grey.white,
            }}
          ></Box>
        </ParallaxLayer>

        {/* INFO / H1 SECTION */}
        <ParallaxLayer offset={0.1}>
          <LandingInfo scroll={scroll} />
        </ParallaxLayer>

        {/*    WORKS SECTION */}
        <ParallaxLayer
          offset={isMobile ? 1 : 1.02}
          speed={0.2}
          style={{
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              paddingTop: isMobile ? "5vh" : undefined,
            }}
          >
            <AppHeading
              title={"News"}
              size={42}
              buttonTitle="Go to all the news"
            />
          </Box>

          <WorksSection data={worksData} />
        </ParallaxLayer>
        <ParallaxLayer
          offset={isMobile ? 2.8 : 2.2}
          style={{
            width: "100%",
            zIndex: 1,
            paddingTop: isMobile ? 1 : undefined,
          }}
          speed={0.8}
        >
          <AppHeading
            title={"music"}
            buttonTitle="Go to all the songs"
            size={42}
          />
          <Music />
          {isMobile ? (
            <Box sx={{ paddingTop: 8 }}>
              <AppHeading
                title={"Works"}
                buttonTitle="Go to all the Work and Scores"
                size={42}
              />
              <Box sx={{ mt: 5 }}>
                {" "}
                <NewsContainer newsData={newsData} />
              </Box>
              <div id="biography"></div>
              <Box sx={{ mt: 10 }}>
                <AppHeading title={"Biography"} size={42} />
                <Box sx={{ pt: 8 }}>
                  {" "}
                  <Biography />
                </Box>
              </Box>
              {isMobile ? undefined : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => scroll(0)}
                    sx={{
                      color: theme.palette.grey.grey,
                      border: `0.5px solid ${theme.palette.grey.grey}`,
                      borderRadius: "5px",
                      mb: "3vh",
                      px: "30px",
                      "&:hover": {
                        backgroundColor: theme.palette.grey.grey90,
                      },
                    }}
                  >
                    GO TO TOP
                  </Button>
                </Box>
              )}
              <Box>
                {" "}
                <CreditsContainer creditsData={creditsData} />
              </Box>
            </Box>
          ) : null}
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 3.65 : 2.99}
          speed={0.8}
          style={{
            paddingTop: isMobile ? "1vh" : "73vh",
            display: isMobile ? "none" : "block",
            width: "100%",
            height: isMobile ? undefined : "130vh",
            zIndex: 1,
          }}
        >
          <AppHeading
            title={"Works"}
            buttonTitle="Go to all the works and scores"
            size={42}
          />
          <NewsContainer newsData={newsData} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 4.3 : 4.15}
          speed={0.8}
          style={{
            display: isMobile ? "none" : "block",
            width: "100%",
          }}
        >
          <AppHeading title={"Biography"} size={42} />
        </ParallaxLayer>
        <ParallaxLayer
          offset={isMobile ? 4.33 : 4}
          speed={0.6}
          style={{
            display: isMobile ? "none" : "block",
            height: "100vh",
            width: "100%",
            paddingTop: isMobile ? 0 : "23vh",
          }}
        >
          {!isMobile ? (
            <Box
              sx={{
                width: "60%",
                height: "0.6px",
                // backgroundColor: theme.palette.grey.white,
                opacity: 0.2,
                mx: "auto",
                mb: "15vh",
              }}
            />
          ) : undefined}

          <div id="biography"></div>
          <Biography />
          {isMobile ? undefined : (
            <Box
              sx={{
                width: "100%",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => scroll(0)}
                sx={{
                  color: theme.palette.grey.grey,
                  border: `0.5px solid ${theme.palette.grey.grey}`,
                  borderRadius: "5px",
                  mb: "3vh",
                  px: "30px",
                  "&:hover": {
                    backgroundColor: theme.palette.grey.grey90,
                  },
                }}
              >
                GO TO TOP
              </Button>
            </Box>
          )}

          <CreditsContainer creditsData={creditsData} />
        </ParallaxLayer>
      </Parallax>
    </Box>
  );
};

export default DefaultLayout;
