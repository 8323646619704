export const navigation = [
  { title: "News", link: "/news" },

  {
    title: "Who I Am",
    link: "/biography",
    to: 4,
  },
  {
    title: "Music",
    link: "/music",
  },
  {
    title: "Works",
    link: "/works",
  },
  {
    title: "Credits",
    link: "/credits",
    to: 5,
  },
  { title: "Contact", link: "/contact" },
];
