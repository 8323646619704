import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../theme/theme.ts";
import { isMobile } from "react-device-detect";

type Props = {
  data?: any;
  rightCard?: boolean;
};
const IFrame = ({ rightCard, data }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: rightCard ? "end" : undefined,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: rightCard
            ? isMobile
              ? "100vw"
              : "20vw"
            : isMobile
            ? "100vw"
            : "40vw",
          height: rightCard ? "80vh" : undefined,
          flexDirection: "column",
          marginLeft: rightCard || isMobile ? "0" : "20vw",
        }}
      >
        <Box
          className="video-responsive"
          sx={{
            width: "100%",
            borderRadius: "2px",
            height: rightCard ? "55vh" : isMobile ? "58vh" : "40vh",
            backgroundImage: `url("${data?.ImageUrl}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: 0.8,
            filter: "grayscale(40%)",
          }}
        />
        {isMobile ? null : (
          <Typography
            className="video-responsive"
            variant="body1"
            sx={{
              color: theme.palette.grey.white,
              py: 1,
              px: isMobile ? 3 : 0,
              lineHeight: isMobile ? 1.3 : 1.5,
              textAlign: isMobile && rightCard ? "right" : undefined,
              fontWeight: 400,
            }}
          >
            {data?.description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default IFrame;
