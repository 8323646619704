import { Box } from "@mui/material";
import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import theme from "../../theme/theme.ts";
import { isMobile } from "react-device-detect";
import spotifyLogo from "./spotify-logo.svg";
import soundcloudLogo from "./snd.svg";

type Props = {};

const SocialBar = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        py: isMobile ? 5 : 0,
        gap: isMobile ? 2 : 1,
        width: isMobile ? "100%" : 100,
      }}
    >
      <a
        href="https://www.instagram.com/lunatik.01/?hl=en"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon
          sx={{
            fontSize: isMobile ? 30 : 22,
            color: theme.palette.secondary.main,
          }}
        />
      </a>

      <a
        href="https://www.youtube.com/@alessandrogiustini3285"
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        <YouTubeIcon
          sx={{
            fontSize: isMobile ? 30 : 22,
            color: theme.palette.secondary.main,
          }}
        />
      </a>

      <a
        href="https://open.spotify.com/artist/3K9dwEtbcJbV6duGfnWyQ7?si=QFR17SE6RQKSHNryJ9P9gw&app_destination=copy-link"
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        <img
          src={spotifyLogo}
          alt="spotify logo"
          style={{
            borderRadius: 5,
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
            width: isMobile ? 30 : 22,
            height: isMobile ? 30 : 22,
          }}
        />
      </a>
      <a
        href="https://soundcloud.com/user-571528127"
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        <img
          src={soundcloudLogo}
          alt="spotify logo"
          style={{
            borderRadius: 5,
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
            width: isMobile ? 30 : 22,
            height: isMobile ? 30 : 22,
          }}
        />
      </a>
    </Box>
  );
};

export default SocialBar;
