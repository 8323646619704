import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { isMobile } from "react-device-detect";
import theme from "../../theme/theme.ts";
import TopBar from "../TopBar/TopBar.tsx";

type Props = {
  scroll: (to: number) => void;
};

const LandingInfo = (props: Props) => {
  const { scroll } = props;
  return (
    <Grid
      sx={{
        gap: isMobile ? "1.2rem" : "1.8rem",
        padding: isMobile ? "2vw" : "8vh 2vw",
        color: theme.palette.secondary.light,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid item>
        {isMobile ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4rem",
              color: theme.palette.secondary.light,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ letterSpacing: "0.7rem", fontSize: "7vw" }}
              variant="h1"
            >
              ALESSANDRO
            </Typography>
            <Typography
              sx={{ letterSpacing: "0.7rem", fontSize: "7vw" }}
              variant="h1"
            >
              GIUSTINI
            </Typography>
          </Box>
        ) : (
          <Typography
            sx={{ letterSpacing: "0.7rem", fontSize: "3vw" }}
            variant="h1"
            component={"h1"}
          >
            ALESSANDRO GIUSTINI
          </Typography>
        )}
      </Grid>
      <Grid sx={{ paddingTop: 2 }} item>
        <Typography
          component={"h2"}
          variant="caption"
          sx={{ letterSpacing: "0.7rem" }}
        >
          MUSIC COMPOSER{" "}
        </Typography>
      </Grid>
      <TopBar scroll={scroll} />
    </Grid>
  );
};

export default LandingInfo;
