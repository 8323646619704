import React from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import ScoresCard from "../../components/UI/AppCard/ScoresCard/ScoresCard.tsx";
import WorksCard from "../../components/UI/AppCard/WorksCard/WorksCard.tsx";
import { isMobile } from "react-device-detect";

type Props = {
  data: any;
};

const WorksSection = ({ data }: Props) => {
  const left = data.filter((item: any) => item.ImageIsHomeLeft === true);
  const right = data.filter((item: any) => item.ImageIsHomeRight === true);
  const scores = data.filter((item: any) => item.ImageIsScoresHome === true);
  return (
    <>
      <ParallaxLayer offset={isMobile ? 0.52 : 0.4}>
        <WorksCard rightCard={false} data={left} />
      </ParallaxLayer>
      <ParallaxLayer offset={isMobile ? 1.2 : 0.8}>
        <WorksCard rightCard={true} data={right} />
      </ParallaxLayer>
      <ParallaxLayer offset={isMobile ? 1.65 : 1.1}>
        <ScoresCard data={scores} />
      </ParallaxLayer>
    </>
  );
};

export default WorksSection;
