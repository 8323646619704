import { Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme/theme.ts";
import { Box } from "@mui/system";
import { ModalButton } from "../Button/LinkButton.tsx";
import { isMobile } from "react-device-detect";

type Props = {
  title: string;
  buttonTitle: string;
  link: string;
  size?: number;
  lower?: boolean;
};

const AppHeading = ({ title, size, lower, buttonTitle, link }: Props) => {
  const upperCase = lower ? title : title.toUpperCase();

  return (
    <Box
      id={title}
      sx={{
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          width: isMobile ? "50vw" : "90%",
          display: "flex",
          paddingBottom: isMobile ? 2 : 0,
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: isMobile ? "center" : undefined,
          borderBottom: isMobile
            ? `solid 0.5px ${
                isMobile
                  ? theme.palette.grey.grey
                  : theme.palette.secondary.main
              }}`
            : undefined,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: isMobile ? 24 : size || 34,
            letterSpacing: "0.5rem",
            textTransform: "uppercase",
            color: isMobile
              ? theme.palette.grey.grey
              : theme.palette.secondary.main,
          }}
        >
          {upperCase}
        </Typography>{" "}
        {isMobile ? undefined : (
          <ModalButton
            link={`/${title}`}
            buttonTitle={isMobile ? "more" : buttonTitle}
          />
        )}
      </Box>
    </Box>
  );
};

export default AppHeading;
