import React from "react";
import { Box, Button, List, Typography } from "@mui/material";
import { navigation } from "../../utils/navigation.ts";
import theme from "../../theme/theme.ts";
import { HashLink } from "react-router-hash-link";
import { isMobile } from "react-device-detect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type Props = {
  scroll: (to: number) => void;
};

const TopBar = (props: Props) => {
  const alignCenter = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100vw",
    height: "70vh",
  };

  const { scroll } = props;
  return (
    <Box sx={{ ...alignCenter }}>
      {" "}
      <List
        sx={{
          display: "flex",
          paddingTop: isMobile ? theme.spacing(6) : theme.spacing(0),
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? theme.spacing(4) : theme.spacing(0),
        }}
      >
        {navigation.map((item) => (
          <Button
            key={item.title}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            {item.title === "Contact" ? (
              <a
                href="mailto:hello@alessandrogiustini.com"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    padding: theme.spacing(0, 2),
                    fontSize: 16,
                    letterSpacing: "0.5rem",
                    color: theme.palette.grey.white80,
                    "&:hover": {
                      color: theme.palette.grey.grey,
                    },
                  }}
                >
                  {item.title}
                </Typography>
              </a>
            ) : item.title === "Credits" || item.title === "Who I Am" ? (
              <Typography
                onClick={() => {
                  scroll(item?.to);
                }}
                sx={{
                  padding: theme.spacing(0, 2),
                  fontSize: 16,
                  letterSpacing: "0.5rem",
                  color: theme.palette.grey.white80,
                  "&:hover": {
                    color: theme.palette.grey.grey,
                  },
                }}
              >
                {item.title}
              </Typography>
            ) : (
              <HashLink
                smooth
                to={item?.link}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    padding: theme.spacing(0, 2),
                    fontSize: 16,
                    letterSpacing: "0.5rem",
                    color: theme.palette.grey.white80,
                    "&:hover": {
                      color: theme.palette.grey.grey,
                    },
                  }}
                >
                  {item.title}
                </Typography>
              </HashLink>
            )}
          </Button>
        ))}
      </List>
      <Box
        style={{
          color: theme.palette.grey.white,
        }}
      >
        {isMobile ? null : (
          <Button onClick={() => scroll(1)}>
            <KeyboardArrowDownIcon
              sx={{ fontSize: 44, paddingBottom: "5vh", color: "white" }}
            />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default TopBar;
