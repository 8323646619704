import { Box, Grid, Typography } from "@mui/material";
import { ParallaxLayer } from "@react-spring/parallax";
import React from "react";
import { isMobile } from "react-device-detect";
import theme from "../../../../theme/theme.ts";
import { LinkButton } from "../../Button/LinkButton.tsx";

type Props = {
  rightCard?: boolean;
  smallLeftCard?: boolean;
  data?: any;
};

const ScoresCard = ({ data }: Props) => {
  return (
    <>
      <ParallaxLayer speed={0.3} offset={0.2}>
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: isMobile ? "100vw" : "40vw",
              height: isMobile ? "60vh" : "45vh",
              flexDirection: "column",
              marginLeft: isMobile ? "0" : "12vw",
            }}
          >
            <Box
              className="video-responsive"
              sx={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${data[0]?.ImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                opacity: 0.8,
                filter: "grayscale(40%)",
              }}
            />
            {isMobile ? null : (
              <Typography
                className="video-responsive"
                variant="body1"
                sx={{
                  color: theme.palette.grey.white,
                  py: 1,
                  lineHeight: 1.5,
                  fontWeight: 400,
                }}
              >
                {data[0]?.description}
              </Typography>
            )}
          </Box>
        </Box>{" "}
      </ParallaxLayer>
      <ParallaxLayer
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "20%",
          zIndex: 0,
        }}
        offset={isMobile ? 0.2 : 0}
        speed={0.2}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            marginRight: isMobile ? undefined : "10vw",

            paddingLeft: isMobile ? "6vw" : undefined,

            gap: 2,
          }}
        >
          <Grid item>
            {isMobile ? null : (
              <Typography
                variant={"caption"}
                sx={{
                  color: theme.palette.grey.white80,
                }}
              >
                HIGHLIGHTED{" "}
              </Typography>
            )}

            <Typography
              variant="h2"
              sx={{
                zIndex: 1,
                lineHeight: isMobile ? 1.4 : undefined,
                fontSize: isMobile ? 23 : "1.5vw",
                width: isMobile ? "100%" : "60%",
                color: theme.palette.grey.white,
                pl: isMobile ? 1 : undefined,
              }}
            >
              {data[0]?.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                zIndex: 1,
                color: theme.palette.grey.white,
              }}
            >
              {data[0]?.producer}{" "}
            </Typography>
          </Grid>
          <Grid item>
            <LinkButton text="WATCH" link={data[0]?.link} />
          </Grid>
        </Grid>
      </ParallaxLayer>{" "}
    </>
  );
};

export default ScoresCard;
