import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
// import required modules
import { Autoplay } from "swiper";
import { isMobile } from "react-device-detect";

export default function AppSwiper({ data }) {
  return (
    <Swiper
      id="home"
      centeredSlides={true}
      effect="fade"
      speed={8000}
      autoplay={{
        delay: 5500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, EffectFade]}
      className="mySwiper"
    >
      {isMobile ? (
        <SwiperSlide>
          <img src={data[0]?.ImageUrl} alt="landing page" />
        </SwiperSlide>
      ) : (
        data?.map((item) => (
          <SwiperSlide key={item.id}>
            <img
              src={item.ImageUrl}
              style={{
                width: "100%",
                filter: "grayscale(90%)",
              }}
              alt="Alessandro Giustini Music landing page"
            />
          </SwiperSlide>
        ))
      )}
    </Swiper>
  );
}
