import { Grid, Typography } from "@mui/material";
import React from "react";
import theme from "../../../../../theme/theme.ts";
import { ParallaxLayer } from "@react-spring/parallax";
import { LinkButton } from "../../../Button/LinkButton.tsx";
import { isMobile } from "react-device-detect";

type Props = {
  rightCard?: boolean;
  data: any;
};

const WorksCardModal = ({ rightCard, data }: Props) => {
  return (
    <ParallaxLayer
      style={{
        width: "100%",
        display: "flex",
        zIndex: 1,
        justifyContent: rightCard ? "end" : "start",
        height: "20%",
      }}
      offset={0}
      speed={0.2}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: rightCard ? undefined : "10vw",
          marginRight: rightCard ? "10vw" : undefined,
          width: rightCard
            ? isMobile
              ? "80%"
              : "18%"
            : isMobile
            ? "80%"
            : "25%",
          gap: 1.5,
        }}
      >
        <Grid item>
          {isMobile ? null : (
            <Typography
              variant={"caption"}
              sx={{
                justifySelf: isMobile ? "end" : undefined,
                fontSize: isMobile ? 15 : undefined,
                color: theme.palette.grey.white80,
              }}
            >
              HIGHLIGHTED{" "}
            </Typography>
          )}
          <Typography
            variant="h2"
            sx={{
              zIndex: 1,
              textAlign: rightCard && isMobile ? "end" : undefined,
              fontSize: isMobile ? 24 : "1.5vw",
              color: theme.palette.grey.white,
            }}
          >
            {data?.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            sx={{
              zIndex: 1,
              fontSize: isMobile ? 16 : "1.2vw",
              color: theme.palette.grey.white,
            }}
          >
            {data?.producer}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
          }}
          item
        >
          <LinkButton text="WATCH" link={data?.link} />
        </Grid>
        {isMobile ? (
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                zIndex: 1,
                fontSize: isMobile ? 16 : "1.2vw",
                color: theme.palette.grey.white80,
              }}
            >
              {data?.description.split(".")[0]}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </ParallaxLayer>
  );
};

export default WorksCardModal;
