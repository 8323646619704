// Description: General types

export type User = {
  id: string;
  name: string;
  email: string;
  password: string;
};

 export type Image = {
   url: string;
   alt: string;
 };

 export type WorkNewsItem = {
   id?: string;
   title: string;
   description: string;
   descriptionFull: string;
   ImageUrl: string;
   ImageIsHomeLeft: false;
   ImageIsHomeRight: false;
   ImageIsScoresHome: false;
   link: string;
   producer: string;
   credits: string[];
   date: string;
 };

 

 export type Song = {
   id: string;
   title: string;
   producer: string;
   file: string;
   date: string;
 };

 export type Credit = {
   id: string;
   name: string;
   imageUrl: string;
 };

 export type Biography = {
   id: string;
   title: string;
   description: string;
   link: string;
 };

 export const formInputs = [
   { name: "title", type: "text" },
   { name: "description", type: "text", rows: 4, multiline: true },
   { name: "descriptionFull", type: "text", rows: 12, multiline: true },
   { name: "link", type: "text" },
   { name: "producer", type: "text" },
   { name: "credits", type: "text" },
   { name: "date", type: "date" },
   { name: "ImageUrl", type: "text" },
   { name: "ImageAlt", type: "text" },
   { name: "ImageIsHomeLeft", type: "checkbox" },
   { name: "ImageIsHomeRight", type: "checkbox" },
   { name: "ImageIsScoresHome", type: "checkbox" },
 ];

 export const formImageInputs = [{ name: "ImageUrl", type: "text" }];

export const collectionTypes = [
  { name: "works" },
  { name: "news" },
  { name: "credits" },
];
export const formTypes: any[] = [
  {
    title: "Works & Scores",
    name: "works",
  },
  {
    title: "News",
    name: "news",
  },
  {
    title: "Biography",
    name: "biography",
  },
  {
    title: "Credits",
    name: "credits",
  },
];
