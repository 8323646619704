import React from "react";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme.ts";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useGetFirebaseStore } from "./service/hooks/useGetFirebaseStore.ts";
import DefaultLayout from "./layout/DefaultLayout.tsx";
import AdminPanel from "./components/Admin/AdminPanel.tsx";
import Items from "./pages/Works/Items.tsx";
import Music from "./pages/Music/Music.tsx";

function App() {
  const { data: imagesData } = useGetFirebaseStore("imagesGallery");

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<DefaultLayout imagesData={imagesData} />} />
          <Route path="/admindatab3920" element={<AdminPanel />} />
          <Route path="/music" element={<Music />} />
          <Route path="/news" element={<Items collection={"works"} />} />
          <Route path="/works" element={<Items collection={"news"} />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
