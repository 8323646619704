import React from "react";
import Credit from "./Credit.tsx";
import theme from "../../theme/theme.ts";
import { Box } from "@mui/system";
import AppHeading from "../UI/AppHeading/AppHeading.tsx";
import { isMobile } from "react-device-detect";

type Props = {
  creditsData: any;
};

const CreditsContainer = ({ creditsData }: Props) => {
  return (
    <Box
      id="credits"
      sx={{
        display: "flex",
        paddingTop: isMobile ? 4 : 4,
        borderTop: `1px solid ${theme.palette.grey.grey90}`,
        background: isMobile ? undefined : theme.palette.grey.black,
        height: isMobile ? "100vh" : "100%",
        flexDirection: "column",
        justifyContent: isMobile ? "center" : "start",
        alignItems: "center",
        overflow: "auto",
        width: "100vw",
        color: theme.palette.grey.white,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: isMobile && "20px",
          height: "10%",
        }}
      >
        <AppHeading title={"Credits"} size={isMobile ? 12 : 22} />
      </Box>

      <Box
        sx={{
          display: "flex",
          height: isMobile ? undefined : "70%",
          flexDirection: "row",
          gap: isMobile ? 4 : undefined,
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          paddingTop: isMobile ? 4 : undefined,
          width: "100%",
          overflow: "scroll",
        }}
      >
        {creditsData?.map((credit, index) => (
          <Credit key={index} credit={credit} />
        ))}
      </Box>
    </Box>
  );
};

export default CreditsContainer;
