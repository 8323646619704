import React from "react";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link, Button, Box } from "@mui/material";
import theme from "../../../theme/theme.ts";
import { isMobile } from "react-device-detect";

type Props = {
  text: string;
  title: string;
  buttonTitle: string;
  link: string;
  isIcon?: boolean;
};

const LinkButton = ({ text, link }: Props) => {
  return (
    <Button
      sx={{
        backgroundColor: "red",
        height: 30,
        width: 150,
        color: "white",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
        fontSize: 14,
      }}
      startIcon={<ArrowOutwardIcon />}
    >
      <Link
        sx={{ textDecoration: "none", color: "white" }}
        href={link}
        target="_blank"
      >
        {text}
      </Link>
    </Button>
  );
};

const ModalButton = ({ link, buttonTitle, isIcon }: Props) => {
  return (
    <Box sx={{ zIndex: 50 }}>
      {buttonTitle && (
        <Link sx={{ textDecoration: "none", color: "white" }} href={link}>
          <Button
            sx={{
              fontSize: isMobile ? 16 : "12px",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              color: theme.palette.secondary.main,
            }}
          >
            {buttonTitle}
            <ArrowOutwardIcon sx={{ fontSize: 16, paddingLeft: 1 }} />
          </Button>
        </Link>
      )}
    </Box>
  );
};

export { LinkButton, ModalButton };
