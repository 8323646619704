import React, { useState } from "react";
import { Button, Typography, Grid, TextField, Box } from "@mui/material";
import { useAddToFirebaseStore } from "../../../../service/hooks/useAddToFirebaseStore.ts";
import { v4 } from "uuid";
import { Navigate } from "react-router-dom";

import {
  formInputs,
  WorkNewsItem,
  collectionTypes,
} from "../../../../types/general.ts";

const Form = () => {
  const [collection, setCollection] = useState<string>("");
  const [navigate, setNavigate] = useState<boolean>(false);

  const [doc, setDoc] = useState<WorkNewsItem>({
    id: "",
    title: "",
    description: "",
    imageUrl: "",
    ImageIsHomeLeft: false,
    ImageIsHomeRight: false,
    ImageIsScoresHome: false,
    link: "",
    producer: "",
    credits: [],
    date: "",
  });
  const { add } = useAddToFirebaseStore(collection, doc);
  // Handles input change event and updates state

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDoc({
      ...doc,
      id: v4(),
      [e.target.name]: e.target.value,
    });
  };

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDoc({
      ...doc,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = () => {
    add();
    setNavigate(true);
  };

  // Observe state change events such as progress, pause, and resume
  return (
    <>
      <Grid
        sx={{
          p: 10,
          gap: "2rem",
          width: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          sx={{ letterSpacing: 1.7, color: "white", fontSize: 42, py: 2 }}
        >
          AGGIUNGI NUOVO - CATEGORIA: {collection.toUpperCase()}
        </Typography>
        {collectionTypes.map((collectionType) => (
          <Box
            key={collectionType.name}
            sx={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <TextField
              type="checkbox"
              label={collectionType.name}
              onChange={(e) => setCollection(collectionType.name)}
              name={collectionType.name}
            />
            <Typography
              variant="body1"
              sx={{
                letterSpacing: 1.7,
                px: 2,
                color: "white",
              }}
            >
              {collectionType.name.toUpperCase()}
            </Typography>
          </Box>
        ))}
        {formInputs.map((input) => (
          <Box
            key={input.name}
            sx={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <TextField
              label={input.type === "checkbox" ? "" : input.name}
              onChange={
                input.type === "checkbox" ? handleChecked : handleChange
              }
              type={input.type}
              name={input.name}
              rows={input?.rows}
              multiline={input?.multiline}
              sx={
                input.type === "checkbox"
                  ? { display: "flex", width: 25, cursor: "pointer" }
                  : { width: "100%" }
              }
            />
            {input.type === "checkbox" && (
              <Typography
                variant="body1"
                sx={{
                  letterSpacing: 1.7,
                  px: 2,
                  color: "white",
                }}
              >
                {input.name === "ImageIsHomeLeft" &&
                  "Aggiungi come Immagine Home SX"}
                {input.name === "ImageIsHomeRight" &&
                  "Aggiungi come Immagine Home DX"}
                {input.name === "ImageIsScoresHome" &&
                  "Aggiungi come Immagine Scores Home"}
              </Typography>
            )}
          </Box>
        ))}
        <Button
          sx={{
            width: "100%",
            backgroundColor: "#3333",
            my: 2,
          }}
          onClick={handleSubmit}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: 34,
              letterSpacing: "0.5rem",
              color: "white",
            }}
          >
            ADD
          </Typography>
        </Button>
        {navigate && (
          <Navigate to={`/${collection ? collection : ""}`} replace={true} />
        )}
      </Grid>
    </>
  );
};

export default Form;
