import React from "react";
import { Box } from "@mui/material";
import Form from "./components/Form/Form.tsx";
import FormImage from "./components/Form/FormImage.tsx";

type Props = {};

const AdminPanel = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        backgroundColor: "#191919",
      }}
    >
      {/* Form for Works and News */}
      <Form />
      <FormImage />
      {/* Data */}
    </Box>
  );
};

export default AdminPanel;
