import { ParallaxLayer } from "@react-spring/parallax";
import React from "react";
import IFrame from "../../../IFrame/IFrame.tsx";
import WorksCardModal from "./components/WorksCardModal.tsx";
type Props = {
  rightCard?: boolean;
  smallLeftCard?: boolean;
  data: any;
};

const WorksCard = ({ rightCard, data }: Props) => {
  return (
    <>
      <ParallaxLayer speed={0.3} offset={0}>
        <IFrame rightCard={rightCard} data={data[0]} />
      </ParallaxLayer>
      <WorksCardModal rightCard={rightCard} data={data[0]} />
    </>
  );
};

export default WorksCard;
