import { Box, Link, Tooltip } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

type Props = {
  credit: any;
};

const Credit = ({ credit }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: isMobile ? "30%" : "14.5%",
        height: "11.5%",
        borderRadius: "10px",
        padding: isMobile ? 0 : 2,
        paddingY: isMobile ? 2 : 8,
      }}
    >
      <Box
        sx={{
          maxWidth: isMobile ? "50%" : "55%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          href={credit?.link}
          target="_blank"
          rel="noopener"
          underline="none"
        >
          <Tooltip title={credit?.title} placement="bottom">
            <img style={{ width: "100%" }} src={credit?.ImageUrl} alt="" />
          </Tooltip>
        </Link>
      </Box>
    </Box>
  );
};

export default Credit;
