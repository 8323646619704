/**
 * This file is imported using next/dynamic in my Next.js app:
 * import dynamic from 'next/dynamic'
 * const Player = dynamic(() => import('components/Player'), { ssr: false })
 */
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Waveform from "./PlayerWaveform.tsx";
import theme from "../../theme/theme.ts";
import { isMobile } from "react-device-detect";

export default function Player({ audio, name, producer }) {
  return (
    <Box
      sx={{
        zIndex: 2,
        width: isMobile ? "80%" : "80%",
        py: isMobile ? 2 : 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid sx={{ alignSelf: "start", py: 2, gap: 4, pr: isMobile ? 4 : 0 }}>
        <Grid item>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.grey.white,
              fontWeight: 600,
              letterSpacing: "1.2px",
              fontSize: isMobile ? 12 : 18,
              pb: 1,
            }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            sx={{ color: theme.palette.grey.white }}
          >
            {producer}
          </Typography>
        </Grid>
      </Grid>{" "}
      <Waveform audio={audio} />
    </Box>
  );
}
