import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import alepost from "../../assets/images/alepost.jpg";
import SocialBar from "../../components/SocialBar/SocialBar.tsx";
import { biography } from "../../locales/biography.ts";
import theme from "../../theme/theme.ts";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

type Props = {};

const Biography = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          color: "#000",
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",

          alignItems: isMobile ? "center" : undefined,
        }}
      >
        <Box
          sx={{
            width: isMobile ? "50%" : "21vw",
            opacity: 0.8,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            src={alepost}
            style={{ width: "100%", filter: "grayscale(70%)" }}
            alt="Alessandro Giustini Music descrizione post"
          />{" "}
        </Box>

        <Box
          sx={{
            width: isMobile ? "50%" : "42%",

            px: 5,
            letterSpacing: "normal",
            lineHeight: 1.3,
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                fontSize: isMobile ? 13 : "1.5vw",
                paddingTop: isMobile ? 3 : 0,
                paddingRight: isMobile ? 1 : 0,
                lineHeight: 1.5,
                color: theme.palette.secondary.main,
              }}
            >
              {biography.aphorism}
            </Typography>
            {isMobile ? (
              <Button
                sx={{
                  color: theme.palette.secondary.main,
                  fontSize: 11,
                  fontWeight: 600,
                  width: "100%",
                  letterSpacing: "0.1rem",
                  textTransform: "uppercase",
                  py: 1,
                  px: 2,
                  mt: 2,
                  borderBottom: `solid 1px ${theme.palette.secondary.main}`,
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.grey.white80,
                  },
                }}
                onClick={() => handleOpen()}
              >
                Open Biography
              </Button>
            ) : undefined}
          </Grid>
          {isMobile ? undefined : (
            <>
              <Grid item>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: isMobile ? 11 : "0.9vw",
                    py: 1.4,
                    lineHeight: 1.5,
                    color: theme.palette.grey.white80,
                  }}
                >
                  {biography.biographyStart}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: isMobile ? 11 : "0.9vw",
                    lineHeight: 1.5,
                    color: theme.palette.grey.grey,
                  }}
                >
                  {biography.biography}
                </Typography>
              </Grid>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          pt: isMobile ? 0 : 5,
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            paddingTop: isMobile ? 2 : 0,
            color: theme.palette.secondary.main,
            fontSize: isMobile ? 12 : 16,
          }}
        >
          WORK WITH ME{" "}
        </Typography>

        {isMobile ? (
          <a
            style={{
              textDecoration: "none",
            }}
            href="mailto:hello@alessandrogiustini.com"
          >
            <Typography
              sx={{
                fontSize: 15,
                color: "white",
                py: isMobile ? 1 : 2,
                textAlign: "center",
              }}
            >
              hello@alessandrogiustini.com
              <br />
              <Typography
                sx={{
                  fontSize: 9,
                }}
              >
                Via Eugenio Barsanti, 25 - 00146 Roma
              </Typography>
            </Typography>
          </a>
        ) : (
          <Typography sx={{ color: "white", py: 2, textAlign: "center" }}>
            hello@alessandrogiustini.com
            <br />
            <Typography
              sx={{
                fontSize: 13,
              }}
            >
              {" "}
              Via Eugenio Barsanti, 25 - 00146 Roma
            </Typography>
          </Typography>
        )}
      </Box>
      {isMobile ? (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              opacity: 0.99,
              width: "100%",
              borderRadius: "4px",
              color: "white",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                borderRadius: "10px",
                padding: isMobile ? 7 : 10,
                paddingTop: 7,
                backgroundColor: theme.palette.grey.grey70,
                gap: 5,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => handleClose()}
                sx={{ color: theme.palette.secondary.main, alignSelf: "start" }}
              >
                <ArrowBackIosNewIcon />
                Go Back
              </Button>

              <Grid item>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: isMobile ? 11 : "0.9vw",
                    py: 1.4,
                    lineHeight: 1.5,
                    color: theme.palette.grey.white80,
                  }}
                >
                  {biography.biographyStart}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: isMobile ? 11 : "0.9vw",
                    lineHeight: 1.5,
                    color: theme.palette.grey.grey,
                  }}
                >
                  {biography.biography}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : undefined}

      <SocialBar />
    </Box>
  );
};

export default Biography;
