import React, { useState } from "react";
import { Button, Typography, Grid, TextField, Box } from "@mui/material";
import { useAddToFirebaseStore } from "../../../../service/hooks/useAddToFirebaseStore.ts";
import { v4 } from "uuid";

import { WorkNewsItem } from "../../../../types/general.ts";
import { formImageInputs } from "../../../../types/general.ts";

const FormImage = () => {
  const [doc, setDoc] = useState<WorkNewsItem>({
    imageUrl: "",
  });
  const { add } = useAddToFirebaseStore("imagesGallery", doc);
  // Handles input change event and updates state
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDoc({
      id: v4(),
      [e.target.name]: e.target.value,
    });
  };

  // Observe state change events such as progress, pause, and resume
  return (
    <>
      <Grid
        sx={{
          p: 10,
          gap: "2rem",
          width: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          sx={{ letterSpacing: 1.7, color: "white", fontSize: 42, py: 2 }}
        >
          AGGIUNGI NUOVA IMMAGINE LANDING PAGE
        </Typography>

        {formImageInputs.map((input) => (
          <Box
            key={input.name}
            sx={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <TextField
              label={input.name}
              onChange={handleChange}
              type={input.type}
              name={input.name}
              rows={input?.rows}
              sx={{ width: "100%" }}
            />
          </Box>
        ))}
        <Button
          sx={{
            width: "100%",
            my: 4,
            backgroundColor: "#3333",
          }}
          onClick={add}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: 34, letterSpacing: "0.5rem", color: "white" }}
          >
            ADD
          </Typography>
        </Button>
      </Grid>
    </>
  );
};

export default FormImage;
