import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { PauseCircleFilled } from "@mui/icons-material";
import { Button } from "@mui/material";
import theme from "../../theme/theme.ts";
import { isMobile } from "react-device-detect";

const Waveform = ({ audio }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const containerRef = useRef();
  const waveSurferRef = useRef();

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      waveColor: theme.palette.grey.white80,
      progressColor: theme.palette.secondary.light,
      barWidth: 0.7,
      height: isMobile ? 60 : 80,
      cursorWidth: 0,
      barGap: 3,
      responsive: true,
    });
    waveSurfer.load(audio);
    waveSurfer.current = waveSurfer;
    waveSurfer.on("ready", () => {
      waveSurferRef.current = waveSurfer;
    });
    //if is playing

    waveSurfer.on("play", () => {
      setIsPlaying(true);
    });
    waveSurfer.on("pause", () => {
      setIsPlaying(false);
    });
    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMobile ? undefined : "0px 19px 10px 5px",
        paddingTop: 0,
        paddingBottom: isMobile ? 10 : 5,
        borderBottom: "0.5px solid #ccc",
      }}
    >
      {/* <audio src={audio} controls /> */}

      <div style={{ width: isMobile ? undefined : "10%" }}>
        <Button
          sx={{
            color: "white",
          }}
          onClick={() => waveSurferRef?.current?.playPause()}
          type="button"
        >
          {isPlaying ? (
            <PauseCircleFilled
              sx={{
                zIndex: 100,
                fontSize: theme.spacing(isMobile ? 5 : 3.5),
              }}
            />
          ) : (
            <PlayCircleIcon
              sx={{ fontSize: theme.spacing(isMobile ? 5 : 3.5) }}
            />
          )}
        </Button>{" "}
      </div>
      <div style={{ width: "90%" }} ref={containerRef}></div>
    </div>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};

export default Waveform;
