import { Box, Button, Divider } from "@mui/material";
import React from "react";
import SmallCard from "../../components/UI/AppCard/SmallCard/SmallCard.tsx";
import { useGetFirebaseStore } from "../../service/hooks/useGetFirebaseStore.ts";
import AppHeading from "../../components/UI/AppHeading/AppHeading.tsx";
import AppCircularProgress from "../../components/UI/CircularProgress/AppCircularProgress.tsx";
import { isMobile } from "react-device-detect";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useLocation } from "react-router-dom";
import theme from "../../theme/theme.ts";

type Props = {
  collection: string;
};

const Items = ({ collection }: Props) => {
  const { data, loading } = useGetFirebaseStore(collection);
const location = useLocation();
const { pathname } = location;
return (
  <Box
    sx={{
      display: "flex",
      width: "100%",
      height: "100vh",
      overflow: "auto",
      flexDirection: "column",
      background: "linear-gradient(#191919 , #080808 )",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        py: 7,
        px: isMobile ? 2 : 6,
      }}
    >
      <AppHeading title={pathname.replace("/", "")} size={42} />
      <Link to="/" style={{ textDecoration: "none" }}>
        <Button
          sx={{
            width: "100px",
            display: isMobile ? "none" : "flex",
            color: isMobile
              ? theme.palette.grey.grey
              : theme.palette.secondary.main,
            alignSelf: "start",
          }}
        >
          <ArrowBackIosNewIcon />
          Go Back
        </Button>
      </Link>
    </Box>
    {isMobile ? undefined : (
      <Divider
        sx={{
          width: isMobile ? "200px" : "80vh",
          alignSelf: "center",
        }}
      />
    )}

    <Box
      sx={{
        width: "100%",
        py: isMobile ? 2 : 12,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "100%" : "98%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: isMobile ? undefined : "wrap",
          justifyContent: "center",
          gap: isMobile ? 0 : 20,
        }}
      >
        {loading ? (
          <AppCircularProgress />
        ) : (
          data.map((item, index) => <SmallCard key={index} item={item} />)
        )}
      </Box>
    </Box>
  </Box>
);
};

export default Items;
