import { Box, Modal, Typography, Button, Divider } from "@mui/material";
import React from "react";
import theme from "../../../../../theme/theme.ts";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Fade from "react-reveal/Fade";
import { isMobile } from "react-device-detect";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Link } from "react-router-dom";

type Props = {
  rightCard?: boolean;
  order?: number;
  item: any;
};

const NewsCardModal = ({ order, item }: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Box
        onClick={() => handleOpen()}
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 0 : 5,
          alignItems: "center",
          width: isMobile ? "80%" : "100%",
          height: "100%",
          "&:hover": {
            "& > div": {
              transform: "scale(1.01)",
              transition: "all 0.5s ease-in-out",
            },
          },
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "50%",
            order: isMobile ? 2 : order,
            height: isMobile ? "50%" : "100%",
          }}
        >
          <img src={item?.ImageUrl} style={{ objectFit: "cover" }} alt="" />
        </Box>{" "}
        <Box
          sx={{
            textAlign: "left",
            padding: "0 15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "100%" : "50%",
            gap: 1,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              zIndex: 1,
              lineHeight: 1.4,
              fontSize: isMobile ? 20 : "1.4vw",
              color: isMobile
                ? theme.palette.grey.white80
                : theme.palette.grey.white,
            }}
          >
            {item?.title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              paddingBottom: isMobile ? 1 : 0,
              zIndex: 1,
              fontSize: isMobile ? 14 : "1vw",
              color: theme.palette.grey.white,
            }}
          >
            {item?.producer}
          </Typography>
          {isMobile ? <Divider /> : undefined}

          {isMobile ? undefined : (
            <Typography
              variant="body1"
              sx={{
                zIndex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: isMobile
                  ? theme.palette.grey.white80
                  : theme.palette.grey.white,
                fontSize: isMobile ? 16 : "1vw",
              }}
            >
              {item?.description.length > 280
                ? item?.description.slice(0, 270) + "..."
                : item?.description}
            </Typography>
          )}
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Fade top>
          <Box
            sx={{
              opacity: 0.99,
              width: "100%",
              borderRadius: "4px",
              color: "white",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                borderRadius: "10px",
                textAlign: isMobile ? "center" : undefined,
                padding: isMobile ? 5 : 10,
                paddingTop: 7,
                backgroundColor: theme.palette.grey.grey70,
                gap: 5,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => handleClose()}
                sx={{ color: theme.palette.secondary.main, alignSelf: "start" }}
              >
                <ArrowBackIosNewIcon />
                Go Back
              </Button>
              <Box
                sx={{
                  width: isMobile ? "80" : "40%",
                  height: "100%",
                }}
              >
                <img
                  src={item?.ImageUrl}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "100%",
                  }}
                  alt="Alessandro Giustini Music post"
                />
              </Box>{" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Link
                  to={item?.link}
                  style={{
                    zIndex: 1,
                    lineHeight: 1.3,
                    fontSize: 18,
                    textDecoration: "none",
                    color: theme.palette.secondary.light,
                    alignItems: "center",
                    gap: 6,
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  WATCH <PlayCircleIcon sx={{ fontSize: 20 }} />
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: isMobile ? 4 : 2,
                  width: isMobile ? "100%" : "60%",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    zIndex: 1,
                    lineHeight: 1.3,
                    fontSize: isMobile ? 20 : "1.4vw",
                    textAlign: "center",
                    color: theme.palette.secondary.light,
                  }}
                >
                  {item?.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    zIndex: 1,
                    textAlign: "center",
                    fontSize: isMobile ? 16 : "1vw",
                    color: theme.palette.grey.white,
                  }}
                >
                  {item?.producer}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: isMobile ? 16 : "1vw",
                    overflow: "auto",
                    fontWeight: "bold",
                    textOverflow: "ellipsis",
                    zIndex: 1,
                    color: theme.palette.grey.white80,
                  }}
                >
                  {item?.description}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: isMobile ? 16 : "1.1vw",
                    overflow: "auto",
                    textOverflow: "ellipsis",
                    zIndex: 1,
                    color: theme.palette.grey.white,
                  }}
                >
                  {item?.descriptionFull}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default NewsCardModal;
