import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from "react-device-detect";
import theme from "../../theme/theme.ts";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
// import required modules
import { Autoplay, Pagination } from "swiper";
import NewsCardModal from "../UI/AppCard/WorksCard/components/NewsCardModal.tsx";
import { Box } from "@mui/system";

export default function NewsContainer({ newsData }) {
  return (
    <>
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "scroll",
            width: "100vw",
            height: "100%",
            gap: 4,
          }}
        >
          {" "}
          {newsData.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <NewsCardModal
                  item={item}
                  order={index % 2 === 0 ? 2 : undefined}
                />
              </SwiperSlide>
            );
          })}
        </Box>
      ) : (
        <Swiper
          slidesPerView={4}
          direction={"vertical"}
          slidesPerGroup={isMobile ? 3 : 4}
          spaceBetween={isMobile ? 30 : 70}
          loopFillGroupWithBlank={true}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          style={{
            width: "85vw",
            cursor: "grab",
            marginTop: isMobile ? "7vh" : "14vh",
            [theme.breakpoints.up("lg")]: {
              marginTop: "0vw",
            },
            height: "160vh",
          }}
        >
          {newsData.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <NewsCardModal
                  item={item}
                  order={index % 2 === 0 ? 2 : undefined}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
}
